import React from 'react'
import cStyles from './CommonStyles.scss'
import styles from './Footer.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={`${cStyles.wrapper} ${styles.footerTop}`}>
      <p>Coral Reef Research Foundation Box 1765, Koror, PW 96940, PALAU</p>
      <p>
        Telephone: +680-488-5255 | Facsimile: +680-488-5513 |
        {' '}
        <a className={styles.link} href="mailto:crrfpalau@gmail.com?Subject=Hello">
          Email Us
        </a>
      </p>
      <div className={styles.linksWrapper}>
        <a href="https://coralreefpalau.org/marine-environments-of-palau/">
          <img
            src={`${process.env.PUBLIC_URL}/public/img/download-book.jpg`}
            alt="Download Marine Environments of Palau"
          />
        </a>
        <a
          href="https://www.facebook.com/CoralReefResearchFoundation/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.fb}
        >
          <img
            src={`${process.env.PUBLIC_URL}/public/img/fb.png`}
            alt="Download Marine Environments of Palau"
          />
        </a>
        <a href="https://coralreefpalau.org/contact/#donate" className={styles.donate}>
          DONATE
        </a>
      </div>
    </div>
    <div className={styles.division} />
    <div className={cStyles.wrapper}>
      <p className={styles.bottomText}>
        <span className={styles.footerChild}>© Coral Reef Research Foundation (CRRF)</span>
        <span className={styles.footerChildDivisor}> | </span>
        <span className={styles.footerChild}>Web page by White Prompt</span>
        <span className={styles.footerChildDivisor}> | </span>
        <span className={`${styles.footerChild} ${styles.canadaFund}`}>
          <span className={styles.footerChild}>Web interface supported by </span>
          <span>
            <div className={styles.canadaFundLogo} />
          </span>
        </span>
      </p>
    </div>
  </div>
)

export default Footer
