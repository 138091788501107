import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'react-responsive-carousel'

const CarouselPhotos = ({ legend, pics }) => (
  <Carousel showArrows={false} showStatus={false} showIndicators={false}>
    {pics.map(pic => (
      <div>
        <img src={`${process.env.PUBLIC_URL}/public/photos/${pic}`} alt={legend} />
      </div>
    ))}
  </Carousel>
)

CarouselPhotos.propTypes = {
  legend: PropTypes.string.isRequired,
  pics: PropTypes.array.isRequired,
}

export default CarouselPhotos
