import Root from './components/Root'
import Detail from './components/DetailContainer'
import DetailList from './components/DetailListContainer'

const routes = [
  {
    key: 'detail',
    path: '/species/:coralId',
    Comp: Detail,
  },
  {
    key: 'list',
    path: '/species-list/:initialId',
    Comp: DetailList,
  },
  {
    key: 'root',
    path: '/',
    Comp: Root,
    exact: true,
  },
]

export default routes
