import '@babel/polyfill'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { hydrate } from 'react-dom'
import App from './components/App'

// window.onload = () => {
// document.addEventListener('contextmenu', event => event.preventDefault())

hydrate(
  <BrowserRouter basename={process.env.BASE_URL}>
    <App />
  </BrowserRouter>,
  document.getElementById('app'),
)
// }
