import React, { Component } from 'react'
import Slider from 'react-slick'
import NoSsr from '@material-ui/core/NoSsr'
import DetailContainer from './DetailContainer'

class DetailListContainer extends Component {
  constructor(props) {
    super(props)

    let list

    try {
      list = JSON.parse(localStorage.getItem('coralsList'))
    } catch (e) {
      list = []
    }

    const { match } = this.props
    const id = match && match.params && match.params.initialId ? parseInt(match.params.initialId, 10) : 0

    let initialSlide = 0

    let splitedList = []
    let page = 0
    let totalPages = 1

    if (list.length > 1000) {
      totalPages = Math.ceil(list.length / 1000)

      for (let i = 0; i < totalPages; i += 1) {
        const until = list.length > 1000 ? 1000 : list.length
        const x = list.splice(0, until)

        if (x.indexOf(id) !== -1) {
          page = i
          initialSlide = x.indexOf(id)
        }

        splitedList.push(x)
      }
    } else {
      splitedList.push(list)
      if (list.indexOf(id) !== -1) {
        page = 0
        initialSlide = list.indexOf(id)
      }
    }

    this.state = {
      splitedList,
      initialSlide,
      loading: false,
      page,
      totalPages,
    }
  }

  SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={`${className} arrowSlider`}
        style={{
          ...style,
        }}
        onClick={onClick}
      />
    )
  }

  SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={`${className} arrowSlider`}
        style={{
          ...style,
        }}
        onClick={onClick}
      />
    )
  }

  render() {
    const {
      splitedList, initialSlide, loading, page, totalPages,
    } = this.state

    const settings = {
      arrows: true,
      dots: false,
      infinite: true,
      lazyLoad: true,
      swipeToSlide: true,
      touchMove: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <this.SampleNextArrow />,
      prevArrow: <this.SamplePrevArrow />,
      initialSlide,
      // beforeChange: () => this.setState({ loading: true }),
      // afterChange: () => setTimeout(() => this.setState({ loading: false }), 100),
    }

    const Loader = () => {
      if (loading) return <div className="slider-loader">LOADING...</div>
      return null
    }

    const Pages = () => {
      if (totalPages > 1) {
        const y = []

        for (let i = 0; i < totalPages; i += 1) {
          const pagingClass = this.state.page === i ? 'active-page' : ''

          y.push(
            <button
              className={pagingClass}
              type="button"
              onClick={() => this.setState({ page: i })}
            >
              {i + 1}
            </button>,
          )
        }

        return y
      }
      return <span />
    }

    const MySlider = props => (
      <Slider {...settings}>
        {splitedList
          && splitedList[props.page]
          && splitedList[props.page].map(coralId => (
            <div>
              <DetailContainer coralId={coralId} />
            </div>
          ))}
      </Slider>
    )

    return (
      <NoSsr>
        <div style={{ position: 'relative' }}>
          <Loader />
          <MySlider page={page} />
          <div className="pager"><Pages /></div>
        </div>
      </NoSsr>
    )
  }
}

export default DetailListContainer
