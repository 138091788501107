import React, { Fragment } from 'react'
import Router from './Router'
import Footer from './Footer'
import Header from './Header'

import '../public/styles.css'

const App = () => (
  <Fragment>
    <Header />
    <Router />
    <Footer />
  </Fragment>
)

export default App
