import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import styles from './CsvDownloadBtn.scss'
import attr from '../../attributes.json'

const CsvDownloadBtn = props => {
  const { data } = props
  const downloadProps = {}
  downloadProps.filename = 'results.csv'
  downloadProps.data = data

  const convertArrayOfObjectsToCSV = args => {
    let result
    let ctr
    let partial

    const originalData = args.data || null
    if (originalData == null || !originalData.length) {
      return null
    }

    const columnDelimiter = args.columnDelimiter || ','
    const lineDelimiter = args.lineDelimiter || '\n'

    const keys = Object.keys(originalData[0])
    keys.shift() // shift() removes the id column

    // Change database key names for csv keys
    const csvKeys = keys.map(key => attr.find(x => x.field === key).label)

    result = ''
    result += csvKeys.join(columnDelimiter)
    result += lineDelimiter

    originalData.forEach(item => {
      ctr = 0
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter

        if (item[key] !== '' && typeof item[key] === 'string') {
          partial = item[key].replace(/"/g, '""')
          partial = partial.replace(/#/g, '') // # breaks the correct creation of the csv file.
          partial = `"${partial}"`
        } else partial = item[key]

        result += partial
        ctr += 1
      })
      result += lineDelimiter
    })

    return result
  }

  window.dataCallback = () => {
    let csv = convertArrayOfObjectsToCSV({
      data: downloadProps.data,
    })

    if (csv == null) return

    const filename = downloadProps.filename || 'results.csv'

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`
    }
    const encodedData = encodeURI(csv)

    const link = document.createElement('a')
    link.setAttribute('href', encodedData)
    link.setAttribute('download', filename)
    link.setAttribute('id', 'downloadCsv')

    const linkContainer = document.getElementById('linkContainer')
    linkContainer.appendChild(link)

    const downloadBtn = document.getElementById('downloadCsv')
    downloadBtn.click()

    window.grecaptcha.reset()
    const recaptchaEl = document.getElementById('reCaptchaContainer')
    recaptchaEl.style.visibility = 'hidden'
  }

  const handleClick = () => {
    try {
      window.onloadCallback()
    } catch (e) {
      // console.log('e: ', e)
    }
    const recaptchaEl = document.getElementById('reCaptchaContainer')
    recaptchaEl.style.visibility = 'visible'
  }

  return (
    <div className={styles.downloadBtnContainer}>
      <Button
        className={`g-recaptcha ${styles.downloadBtn}`}
        handleClick={handleClick}
        type="primary"
        value="Download Results"
      />
      <div
        id="reCaptchaContainer"
        className={`g-recaptcha ${styles.recaptcha}`}
        data-sitekey={process.env.RECAPTCHA_KEY}
        data-callback="dataCallback"
        data-size="compact"
      />
      <span id="linkContainer" />
    </div>
  )
}

CsvDownloadBtn.propTypes = {
  data: PropTypes.array.isRequired,
}

export default CsvDownloadBtn
