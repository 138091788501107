import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import styles from './Detail.scss'
import cStyles from './CommonStyles.scss'
import Carousel from './Carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const Detail = props => {
  const {
    catNum, fullName, details, photos,
  } = props
  const fields = []

  if (details) {
    details.forEach(el => {
      fields.push(
        <div className={styles.gridRow}>
          <span className={styles.gridParam}>{`${el[0]}: `}</span>
          <span className={styles[el[2]] || ''}>{el[1]}</span>
        </div>,
      )
    })

    return (
      <div className={`${styles.table} ${styles.detailWrapper}`}>
        <span className={styles.mobile}>
          <div className={styles.title}>{catNum}</div>
          <div className={styles.subTitle}>{fullName}</div>
        </span>
        <div className={styles.tableCell}>
          <div className={styles.carousel}>
            {(photos !== null && <Carousel legend={catNum} pics={photos} />) || (
              <div className={styles.nopicWrapper}>
                <div className={styles.nopic}>No picture available</div>
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.tableCell} ${styles.tableCellData}`}>
          <span className={styles.desktop}>
            <div className={styles.title}>{catNum}</div>
            <div className={styles.subTitle}>{fullName}</div>
          </span>
          <Paper className={styles.paper}>
            <div>{fields}</div>
          </Paper>
        </div>
      </div>
    )
  }

  return (
    <div className={`${styles.table} ${cStyles.wrapper}`}>
      <span className={styles.mobile}>
        <div className={styles.title}>-</div>
        <div className={styles.subTitle}>-</div>
      </span>
      <div className={styles.tableCell}>
        <div className={styles.carousel} />
      </div>
      <div className={`${styles.tableCell} ${styles.tableCellData}`}>
        <span className={styles.desktop}>
          <div className={styles.title}>-</div>
          <div className={styles.subTitle}>-</div>
        </span>
        <Paper className={styles.paper}>
          <div />
        </Paper>
      </div>
    </div>
  )
}

Detail.propTypes = {
  catNum: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  details: PropTypes.array.isRequired,
  photos: PropTypes.array.isRequired,
}

export default Detail
