/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import NoSsr from '@material-ui/core/NoSsr'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import ResultsGrid from './ResultsGrid'
import ScrollButton from './ScrollButton'
import { getGridData } from '../services'
import About from './About'
import Select from './Select'
import Autosuggest from './Autosuggest'
import Button from './Button'
import camelCased from '../helpers/camelCased'
import styles from './Root.scss'
import cStyles from './CommonStyles.scss'
import attr from '../../attributes.json'
import selectDataForm from '../../form_selectors_data.json'

class Root extends Component {
  constructor() {
    super()

    let cField
    const inputs = {}
    const selectors = {}

    attr.forEach(a => {
      if (a.query === false) return
      cField = camelCased(a.field)
      inputs[cField] = ''
      selectors[`${cField}Selector`] = null
    })

    this.initState = {
      ...inputs,
      // ...selectors,
      grid: [],
      noResults: false,
      noFilterMsg: false,
      progressWheel: false,
      formError: null,
      clear: true,
    }

    this.state = this.initState

    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClear = this.handleClear.bind(this)
  }

  componentDidMount() {
    this.setState({ clear: false })
  }

  componentDidUpdate() {
    if (this.state.clear) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ clear: false })
    }
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleSelectChange(event) {
    this.setState({ [event.name]: event.value })
  }

  handleClear() {
    this.setState(this.initState)
  }

  async handleSubmit(event) {
    event.preventDefault()
    this.setState({ progressWheel: true })

    const body = []
    let formError = null
    let grid

    attr.forEach(a => {
      if (a.query === false) return

      const cField = camelCased(a.field)

      if (a.field === 'depth_min' || a.field === 'depth_max') {
        if (Number.isNaN(this.state[cField])) {
          formError = 'Enter only numbers in depth fileds'
        }
      }

      if (this.state[cField] !== '') {
        body.push([a.field, this.state[cField]])
      }
    })

    const progressWheel = false
    let noResults = false
    let noFilterMsg = false

    if (formError === null) {
      const gridResults = body.length > 0 ? await getGridData(body) : 'no-filter'

      if (gridResults.error) {
        formError = gridResults.error
      } else if (gridResults === 'no-filter') {
        noFilterMsg = true
      } else {
        noResults = gridResults.length === 0
      }

      grid = gridResults
    }

    this.setState({
      noFilterMsg,
      noResults,
      progressWheel,
      grid,
      formError,
    })
  }

  render() {
    const { classes } = this.props

    const fields = []
    // let range
    let j = 1
    let x
    const suffix = ['odd', 'even']

    attr.forEach(d => {
      if (d.query === false) return

      j += 1
      x = `formElementWrapper-${suffix[j % 2]}`

      if (d.type === 'select') {
        fields.push(
          <Grid item xs={12} sm={6} className={`${styles[x]}`}>
            <Select
              placeholder={d.placeholder}
              label={d.label}
              // value={this.state[camelCased(d.field)]}
              name={camelCased(d.field)}
              onChange={this.handleSelectChange}
              suggestions={selectDataForm[d.field]}
              clear={this.state.clear}
            />
          </Grid>,
        )
      } else if (d.type === 'suggest') {
        fields.push(
          <Grid item xs={12} sm={6} className={`${styles[x]}`}>
            <Autosuggest
              placeholder="Start typing for suggestions"
              label={d.label}
              // value={this.state[camelCased(d.field)]}
              name={camelCased(d.field)}
              onChange={this.handleSelectChange}
              suggestions={selectDataForm[d.field]}
              clear={this.state.clear}
            />
          </Grid>,
        )
      } else {
        const labelProps = {}
        labelProps.classes = { root: classes.label }

        fields.push(
          <Grid item xs={12} sm={6} className={`${styles[x]}`}>
            <TextField
              placeholder={d.placeholder || null}
              label={d.label}
              value={this.state[camelCased(d.field)]}
              name={camelCased(d.field)}
              onChange={this.handleInputChange(camelCased(d.field))}
              fullWidth
              margin="normal"
              variant="filled"
              InputLabelProps={labelProps}
              InputProps={{
                inputProps: {
                  className: classes.input,
                },
              }}
            />
          </Grid>,
        )
      }
    })

    const RenderResults = ({ dataGrid }) => {
      const { formError } = this.state

      if (formError) {
        return <div className={`${cStyles.wrapper} ${styles.messageInner}`}>{formError}</div>
      }

      if (this.state.noResults) {
        return <div className={`${cStyles.wrapper} ${styles.messageInner}`}>No results.</div>
      }

      if (this.state.noFilterMsg) {
        return (
          <div className={`${cStyles.wrapper} ${styles.messageInner}`}>
            Please select some filter before searching.
          </div>
        )
      }

      if (this.state.progressWheel) {
        return (
          <div className={`${cStyles.wrapper} ${styles.messageInner} ${styles.progressWheel}`}>
            <CircularProgress />
            <div className={styles.progressWheelTxt}>Searching...</div>
          </div>
        )
      }

      if (dataGrid.constructor === Array && dataGrid.length > 0) {
        return (
          <div className={`${cStyles.wrapper} ${styles.gridInner}`}>
            <ResultsGrid dataGrid={dataGrid} />
            <ScrollButton scrollStepInPx="50" delayInMs="7" />
          </div>
        )
      }

      return ''
    }

    return (
      <Fragment>
        <About />
        <div className={styles.queryForm}>
          <h2 className={`${cStyles.wrapper} ${styles.formTitle}`}>
            Search the Coral Reef Research Foundation Marine Collection Database
          </h2>
          <NoSsr>
            <form className={cStyles.wrapper} onSubmit={this.handleSubmit}>
              <Grid container spacing={12}>
                <div className={styles.fieldsWrapper}>
                  <Grid container spacing={12}>
                    {fields}
                  </Grid>
                </div>
                <div className={styles.btnsWrapper}>
                  <div className={styles.btnsInner}>
                    <label htmlFor="contained-button-file">
                      <input
                        type="submit"
                        id="contained-button-file"
                        className={styles.querySubmit}
                      />
                      <Button type="primary" value="SEARCH" />
                    </label>
                    <Button type="clean" value="CLEAR" handleClick={this.handleClear} />
                  </div>
                </div>
              </Grid>
            </form>
          </NoSsr>
        </div>
        <div className={styles.gridWrapper}>
          <RenderResults dataGrid={this.state.grid} />
        </div>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/public/img/bg_footer.jpg`}
            alt="bg_footer"
            className={styles.footerPicture}
          />
        </div>
      </Fragment>
    )
  }
}

Root.propTypes = {
  classes: PropTypes.object.isRequired,
}

const stylesTheme = () => ({
  input: {
    backgroundColor: '#eaeaea',
    padding: '25px 12px 12px;',
  },
  label: {
    top: -3,
  },
})

export default withStyles(stylesTheme, {})(Root)
