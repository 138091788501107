import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const Button = props => {
  const {
    classes, value, type, handleClick,
  } = props

  return (
    <span
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleClick}
      className={`${classes.button} ${classes[type]}`}
    >
      {value}
    </span>
  )
}

const styles = () => ({
  button: {
    cursor: 'pointer',
    padding: '10px 16px',
    fontSize: '0.9em',
    minWidth: 64,
    minHeight: 36,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 4,
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  primary: {
    backgroundColor: 'rgba(0, 91, 95, 1)',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgba(0, 91, 95, .9)',
      color: '#ffffff',
      boxShadow: 'none',
    },
  },
  clean: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 91, 95, 1)',
    fontWeight: 600,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'rgba(0, 91, 95, .9)',
    },
  },
})

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(Button)
