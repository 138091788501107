import React from 'react'
import Detail from './Detail'
import { getCoralDetail } from '../services'
import monthMapping from '../helpers/monthMapping'

const detailsParams = [
  ['Genus', 'genus', 'cientific'],
  ['Species', 'species', 'cientific'],
  ['Phylum', 'phylum'],
  ['Class', 'class'],
  ['Order', 'order'],
  ['Family', 'family'],
  ['Identified by', 'identified_by'],
  ['Locality', 'locality_name'],
  ['Latitude', 'latitude'],
  ['Longitude', 'longitude'],
  ['Depth', 'depth'],
  ['Depth range', 'depth_range'],
  ['Collection date', 'collection_date'],
  ['Collector', 'collector'],
  ['Original Fixative', 'orig_fix'],
  ['Other Museum specimens', 'museum_specimens'],
]

class DetailContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      details: null,
      catNum: '',
      fullName: '',
    }
  }

  async componentDidMount() {
    const { match, coralId, fullDetails } = this.props
    const id = coralId || (match && match.params && match.params.coralId)

    const details = fullDetails || (await getCoralDetail(id))

    let value
    let label
    let colDate
    const d = []

    detailsParams.forEach(el => {
      const dCont = []

      value = details[el[1]] || '-'
      label = el[0]

      if (label === 'Depth range') label = `${label} (m)`

      if (value !== '-') {
        switch (label) {
          case 'Depth':
            value = `${value} m`
            break
          case 'Latitude':
          case 'Longitude':
            value = Number(value).toFixed(5)
            break
          case 'Collection date':
            colDate = value.split('-')
            value = `${colDate[1]}-${monthMapping(colDate[0])}-${colDate[2]}`
            break
          default:
        }
      }

      dCont.push(label)
      dCont.push(value)
      if (el[2]) dCont.push(el[2])

      d.push(dCont)
    })

    const photos = (details.photos && details.photos.split(',')) || null

    this.setState({
      details: d,
      catNum: details.catalog_number,
      fullName: details.full_name,
      photos,
    })
  }

  render() {
    return <Detail {...this.state} />
  }
}

export default DetailContainer
