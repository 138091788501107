/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import cStyles from './CommonStyles.scss'
import styles from './Header.scss'

const coralUrl = process.env.CORAL_URL

const navBtns = [
  { value: 'HOME', link: coralUrl },
  { value: 'FACILITIES', link: `${coralUrl}facilities/` },
  { value: 'RESEARCH', link: `${coralUrl}research/` },
  { value: 'EDUCATION', link: `${coralUrl}education/` },
  { value: 'DATA & PUBLICATIONS', link: `${coralUrl}marine-environments-of-palau/` },
  { value: 'PROJECTS', link: `${coralUrl}projects/dugongs/` },
  { value: 'OUR TEAM', link: `${coralUrl}our-team/` },
  { value: 'CONTACT', link: `${coralUrl}contact/` },
]

const Header = props => {
  const { match } = props
  const classy = match.isExact ? styles.root : styles.detail
  const { navBtnNarrow, navBtnWide, navBtnDecorator } = styles

  const burgerToggle = () => {
    const burgerJoint = document.getElementsByClassName('burgerJoint')

    for (let i = 0; burgerJoint.length > i; i += 1) {
      burgerJoint[i].style.display = burgerJoint[i].style.display === 'block' ? 'none' : 'block'
    }
  }

  return (
    <div className={`${styles.header} ${classy}`}>
      <div className={styles.topHeader}>
        <a href={process.env.PUBLIC_URL} className={styles.logoWrapper}>
          <span className={styles.logo} />
        </a>
        <div className={styles.navWide}>
          <div className={styles.wideDiv}>
            {navBtns.map(el => (
              <a className={navBtnWide} href={el.link}>
                <div className={navBtnDecorator}>{el.value}</div>
              </a>
            ))}
          </div>
        </div>
        <div className={styles.navNarrow}>
          <input
            className={`${styles.navNarrowShadow} burgerJoint`}
            onClick={burgerToggle}
            type="button"
          />
          <input className={styles.burgerIcon} onClick={burgerToggle} type="button" />
          <div className={`${styles.narrowLinks} burgerJoint`}>
            {navBtns.map(el => (
              <a className={navBtnNarrow} href={el.link}>
                <div className={navBtnDecorator}>{el.value}</div>
              </a>
            ))}
          </div>
        </div>
      </div>
      {match.isExact && (
        <div className={cStyles.wrapper}>
          <div className={`${styles.headerTitle} burgerJoint`} style={{ display: 'block' }}>
            Biodiversity Database
            <span className={styles.lineDecorator} />
          </div>
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Header)
