import React from 'react'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import ReactAutosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

function inputComponent(props) {
  return <input {...props} />
}

function renderInputComponent(inputProps) {
  const {
    classes, value, label, ...other
  } = inputProps

  const labelProps = {}
  labelProps.classes = { root: classes.label }

  if (value !== '') {
    labelProps.shrink = true
  }

  return (
    <TextField
      fullWidth
      margin="normal"
      variant="filled"
      label={label}
      InputLabelProps={labelProps}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          value,
        },
      }}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query)
  const parts = parse(suggestion, matches)

  if (parts.length > 0) {
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => (part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )))}
        </div>
      </MenuItem>
    )
  }

  return (
    <MenuItem selected={isHighlighted} component="div">
      <span key="0" style={{ fontWeight: 300, color: '#777777' }}>
        No options
      </span>
    </MenuItem>
  )
}

function getSuggestions(value, options) {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0

  return inputLength === 0
    ? []
    : options.filter(suggestion => {
      const keep = count < 10 && suggestion.slice(0, inputLength).toLowerCase() === inputValue

      if (keep) {
        count += 1
      }

      return keep
    })
}

function getSuggestionValue(suggestion) {
  return suggestion
}

class Autosuggest extends React.Component {
  state = {
    value: '',
    suggestions: [],
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { suggestions } = this.props

    this.setState({
      suggestions: getSuggestions(value, suggestions),
    })
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  handleChange = name => (event, { newValue }) => {
    const { onChange } = this.props

    this.setState({
      value: newValue,
    })

    onChange({ name, value: newValue || '' })
  }

  render() {
    const {
      clear, classes, label, name, placeholder,
    } = this.props

    if (clear) this.setState({ value: '' })

    const { value, suggestions } = this.state

    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
    }

    return (
      <div className={classes.root}>
        <ReactAutosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label,
            placeholder,
            value,
            onChange: this.handleChange(name),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => {
            const { containerProps, children } = options

            return (
              <Paper {...containerProps} square>
                {children}
              </Paper>
            )
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -7,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  label: {
    top: -3,
  },
  input: {
    display: 'flex',
    backgroundColor: '#eaeaea',
    padding: '25px 12px 12px',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
})

Autosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Autosuggest)
