/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import NoSsr from '@material-ui/core/NoSsr'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import CsvDownloadBtn from './CsvDownloadBtn'
import convert from '../helpers/convertToFileName'

// const rowsPartial = []

let counter = 0
function createData(gridArgs) {
  counter += 1
  return {
    id: counter, ...gridArgs,
  }
}

function desc(a, b, orderBy) {
  const order = orderBy === 'locality' ? 'locality_name' : orderBy
  const elA = a[order]
  const elB = b[order]

  if (elB < elA) {
    return -1
  }
  if (elB > elA) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => -desc(a, b, orderBy) : (a, b) => desc(a, b, orderBy)
}
/*
attr.forEach(a => {
  if (a.grid === false) return
  rowsPartial.push({ id: a.field, numeric: a.numeric || false, label: a.label })
})

const rows = rowsPartial.map(d => ({
  id: d.id,
  numeric: d.numeric || false,
  label: d.label,
}))
*/
const columns = [
  {
    id: 'picture',
    label: '',
  },
  {
    id: 'catalog_number',
    label: 'Catalog number',
  },
  {
    id: 'phylum',
    label: 'Phylum',
  },
  {
    id: 'class',
    label: 'Class',
  },
  {
    id: 'order',
    label: 'Order',
  },
  {
    id: 'family',
    label: 'Family',
  },
  {
    id: 'genus',
    label: 'Genus',
  },
  {
    id: 'species',
    label: 'Species',
  },
  {
    id: 'depth',
    label: 'Depth (m)',
  },
  {
    id: 'locality',
    label: 'Locality',
  },
]

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  };

  render() {
    const { order, orderBy, classes } = this.props

    return (
      // <TableHead>
      <div className={classes.tableHeaderRow}>
        {columns.map(col => (
          <TableCell
            key={col.id}
            numeric={false}
            sortDirection={orderBy === col.id ? order : false}
            className={classNames(classes.tableCell, {
              [classes.lgHeadCell]: col.id === 'locality',
              [classes.mdHeadCell]: col.id === 'depth',
              [classes.smHeadCell]: col.id === 'picture',
            })}
          >
            <Tooltip
              title="Sort"
              placement={col.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === col.id}
                direction={order}
                onClick={this.createSortHandler(col.id)}
                className={classes.tableSortLabel}
              >
                {col.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ), this)}
      </div>
      // </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
}

const initialOrderBy = 'catalog_number'
const rowsPerPageDefault = 50

// eslint-disable-next-line react/no-multi-comp
class EnhancedTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'desc',
      orderBy: initialOrderBy,
      data: this.arrangeData(props.dataGrid),
      page: 0,
      rowsPerPage: rowsPerPageDefault,
    }
  }

  arrangeData = data => {
    const dataGrid = []

    if (data && data.length > 0) {
      data.forEach(d => {
        dataGrid.push(createData(d))
      })
    }

    return dataGrid
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  };

  /*
  handleClick = (event, id) => {
    // eslint-disable-next-line no-console
    console.log('clicked id: ', id)
  };
  */

  handleChangePage = (event, page) => {
    this.setState({ page })
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  };

  render() {
    const { classes } = this.props
    const {
      data, order, orderBy, rowsPerPage, page,
    } = this.state
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    if (!data || data.length === 0) return ''

    const Pagination = () => (
      <TablePagination
        labelRowsPerPage="Rows"
        rowsPerPageOptions={[50, 100, 150, 200, 250]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
        classes={classes}
      />
    )

    const idsList = []

    stableSort(data, getSorting(order, orderBy))
      .map(el => {
        idsList.push(el.id)
      })

    try {
      localStorage.setItem('coralsList', JSON.stringify(idsList))
    } catch (e) {
      console.log(e)
    }

    return (
      <Paper className={classes.root}>
        <NoSsr><CsvDownloadBtn data={data} /></NoSsr>
        <Pagination />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              classes={classes}
            />
            {
              stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  let depth

                  // eslint-disable-next-line prefer-destructuring
                  if (n.depth === 0 || n.depth) depth = n.depth
                  else depth = '-'

                  return (
                    <div
                      className={classes.tableRow}
                      tabIndex={-1}
                      key={n.id}
                    >
                      <a
                        href={`${process.env.PUBLIC_URL}/species-list/${n.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.rowLink}
                      >
                        <div className={`${classes.tableCell} ${classes.tableCellPic} ${classes.smHeadCell}`}>
                          <img className={classes.thumbnail} src={`${process.env.PUBLIC_URL}/public/thumbnails/${convert(n.catalog_number)}_thumb.jpg`} alt={n.catalog_number || 'thumbnail'} />
                        </div>
                        <div className={classes.tableCell}>
                          {n.catalog_number || '-'}
                        </div>
                        <div className={classes.tableCell}>
                          {n.phylum || '-'}
                        </div>
                        <div className={classes.tableCell}>
                          {n.class || '-'}
                        </div>
                        <div className={classes.tableCell}>
                          {n.order || '-'}
                        </div>
                        <div className={classes.tableCell}>
                          {n.family || '-'}
                        </div>
                        <div className={classes.tableCell}>
                          {n.genus || '-'}
                        </div>
                        <div className={classes.tableCell}>
                          {n.species || '-'}
                        </div>
                        <div className={`${classes.tableCell} ${classes.mdHeadCell}`}>
                          {depth}
                        </div>
                        <div className={`${classes.tableCell} ${classes.lgHeadCell}`}>
                          {n.locality_name || '-'}
                        </div>
                      </a>
                    </div>
                  )
                })
              }
            {/* emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>TableCell
            ) */}
          </Table>
        </div>
        <Pagination />
      </Paper>
    )
  }
}

const styles = () => ({
  lgHeadCell: {
    maxWidth: '300px !important',
    minWidth: '250px !important',
    // textAlign: 'left',
  },
  mdHeadCell: {
    minWidth: '90px !important',
    maxWidth: '90px !important',
  },
  smHeadCell: {
    minWidth: '60px !important',
    maxWidth: '60px !important',
  },
  root: {
    width: '100%',
    padding: '0',
    boxShadow: 'none',
    position: 'relative',
  },
  table: {
    display: 'flex !important',
    flexFlow: 'column wrap',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    padding: '0 8px',
    textAlign: 'center',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 114,
    maxWidth: 114,
    border: 'none',
  },
  tableCellPic: {
    position: 'relative',
  },
  tableCellNumeric: {
    padding: '0 8px',
    textAlign: 'center',
    fontSize: 14,
  },
  tableHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 6,
    paddingBottom: 6,
    borderBottom: '1px dotted #ccc',
    borderSpacing: 'unset',
    borderCollapse: 'unset',
    fontWeight: '600',
    color: '#555555',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 6,
    paddingBottom: 6,
    borderBottom: '1px dotted #ccc',
    borderSpacing: 'unset',
    borderCollapse: 'unset',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
  tableSortLabel: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: '-24px',
    },
  },
  rowLink: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    color: '#222222',
  },
  thumbnail: {
    height: 40,
    width: 40,
    borderRadius: 50,
    verticalAlign: 'middle',
  },
  link: {
    display: 'contents',
  },
  toolbar: {
    padding: '0 !important',
  },
})

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataGrid: PropTypes.object.isRequired,
}

export default withStyles(styles)(EnhancedTable)
