import React from 'react'
import styles from './About.scss'
import cStyles from './CommonStyles.scss'

const About = () => (
  <div className={styles.infoWrapper}>
    <div className={cStyles.wrapper}>
      <img
        src={`${process.env.PUBLIC_URL}/public/img/yellow-wave.png`}
        alt="wave decorator"
        className={styles.waveDecorator}
      />
      <h2>ABOUT MARINE COLLECTIONS DATABASE</h2>
      <p className={styles.text}>
        This specimen database covers marine invertebrates and marine plants that were&nbsp;
        <a
          href="https://coralreefpalau.org/research/coral-reefs/species-diversity/marine-collections-program/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          collected by the Coral Reef Research Foundation
        </a>
        &nbsp;(CRRF) under contract to the&nbsp;
        <a
          href="https://dtp.cancer.gov/organization/npb/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          U.S. National Cancer Institute, Natural Products Branch
        </a>
        , which also funded their taxonomic identification. A variety of other specimens were
        collected by CRRF through other projects. Updated higher sponge taxonomy is not yet
        consistent throughout the full database. The data are based on museum specimens deposited
        at: California Academy of Sciences, Invertebrate Zoology (CASIZ), US Algal Collection of the
        US National Herbarium (US Alg. Coll), US National Herbarium (US), National Institute of
        Water and Atmospheric Research (NIWA), Museum and Art Gallery of the Northern Territory
        (NTM) and Muséum national d&apos;Histoire naturelle, Paris (MNHN). A complete set of
        reference specimens from the 22 years of collections is also held at the Smithsonian
        Institution, however these are held separate from the accessioned collection and require
        special permission to access. CRRF fish specimens are not included in this database and have
        been deposited at the Royal Ontario Museum, The Field Museum, B.P. Bishop Museum and other
        institutions.
      </p>
    </div>
  </div>
)

export default About
